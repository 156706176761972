<template>
    <div class="celebrate-content">
        <div class="celebrate-title">知识调研抽奖</div>
        <div class="celebrate-main">
            <div style="width: 100%">
                <div class="celebrate-item" style="border-right: 0;width: 100%">
                    <el-button type="primary" class="btn" @click="lucky(5)">{{ type==5?'正在抽奖中...':'原价3688/年会员权益' }}</el-button>
                    <h3>获奖名单</h3>
                    <ul style="height: 50px">
                        <li v-for="(item,index) in prize5">{{ item.phone }}</li>
                    </ul>
                </div>
            </div>

            <div class="celebrate-item" style="border-left: 0;border-right: 2px solid #fff;">
                <el-button type="primary" class="btn" @click="lucky(7)">{{ type==7?'正在抽奖中...':'《泌尿腹腔镜与机器人手术专业书籍》' }}</el-button>
                <h3>获奖名单</h3>
                <ul style="justify-content: center;">
                    <li v-for="(item,index) in prize7">{{ item.phone }}</li>
                </ul>
            </div>
            <div class="celebrate-item">
                <el-button type="primary" class="btn" @click="lucky(6)">{{ type==6?'正在抽奖中...':'《尿石症问与答》' }}</el-button>
                <h3>获奖名单</h3>
                <ul style="justify-content: left">
                    <li v-for="(item,index) in prize6">{{ item.phone }}</li>
                </ul>
            </div>
        </div>



        <el-dialog
                title="抽奖滚动"
                :title="type==5?'原价3688/年会员权益':type==6?'《尿石症问与答》':type==7?'《泌尿腹腔镜与机器人手术专业书籍》':'抽奖滚动'"
                :visible.sync="type"
                width="30%"
                :modal="false"
                :show-close="false"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <div style="text-align: center">
                <div class="prizeNum">
                    <template v-if="type == 5">
                        {{ prizeNum5 }}
                    </template>
                    <template v-if="type == 6">
                        <div v-for="(item,index) in prizeNum6" style="display: inline-block;margin-right: 40px;">{{ item }}</div>
                    </template>
                    <template v-if="type == 7">
                        <div v-for="(item,index) in prizeNum7" style="display: inline-block;margin-right: 40px;">{{ item }}</div>
                    </template>
                </div>
                <el-button type="primary" @click="stopLucky()">停止抽奖</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                typeVisible: false,
                type: 0,
                prize5: [],
                prize6: [],
                prize7: [],

                prizeNum5: '***********',
                prizeNum7: [
                    '***********',
                    '***********',
                ],
                prizeNum6: [
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                    '***********',
                ],
                times: null
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            handleClose(done) {
                clearInterval(this.times)
                done();
            },
            getList() {
                this.$axios.post(this.apiUrl.luckDrawYear_All, {}).then(res => {
                    // console.log(res)
                    this.prize5 = [];
                    this.prize6 = [];
                    this.prize7 = [];
                    res.data.map(item => {
                        if(item.type == 5) {
                            this.prize5.push(item)
                        } else if(item.type == 6) {
                            this.prize6.push(item)
                        } else if(item.type == 7) {
                            this.prize7.push(item)
                        }
                    })
                })
            },
            lucky(type) {
                this.$axios.post(this.apiUrl.luckDrawSurvey2, {
                    type
                }).then(res2 => {
                    if(res2.code == 1) {
                        if(type == this.type) {
                            this.stopLucky();
                        }else if(this.type>0 && this.type != type){
                            this.$message.warning(`请先停止抽奖`)
                        }else{
                            this.type = type;
                            this.typeVisible = true;
                            this.times = setInterval(()=> {
                                if(this.type == 5) {
                                    let arr = []
                                    arr[0] = 1;
                                    let two = [3,4,5,7,8,9];
                                    arr[1] = two[Math.floor((Math.random()*two.length))];
                                    arr[2] = Math.floor(Math.random()*10);
                                    arr[3]=arr[4]=arr[5]=arr[6] = '*';
                                    arr[7] = Math.floor(Math.random()*10);
                                    arr[8] = Math.floor(Math.random()*10);
                                    arr[9] = Math.floor(Math.random()*10);
                                    arr[10] = Math.floor(Math.random()*10);
                                    this.prizeNum5 = arr.join('')
                                }else if(this.type == 6){
                                    let arr = []
                                    for (let i = 0;i<30;i++) {
                                        let obj = []
                                        obj[0] = 1;
                                        let two = [3,4,5,7,8,9];
                                        obj[1] = two[Math.floor((Math.random()*two.length))];
                                        obj[2] = Math.floor(Math.random()*10);
                                        obj[3]=obj[4]=obj[5]=obj[6] = '*';
                                        obj[7] = Math.floor(Math.random()*10);
                                        obj[8] = Math.floor(Math.random()*10);
                                        obj[9] = Math.floor(Math.random()*10);
                                        obj[10] = Math.floor(Math.random()*10);
                                        arr.push(obj.join(''))
                                    }
                                    this.prizeNum6 = arr
                                }else if(this.type == 7){
                                    let arr = []
                                    for (let i = 0;i<2;i++) {
                                        let obj = []
                                        obj[0] = 1;
                                        let two = [3,4,5,7,8,9];
                                        obj[1] = two[Math.floor((Math.random()*two.length))];
                                        obj[2] = Math.floor(Math.random()*10);
                                        obj[3]=obj[4]=obj[5]=obj[6] = '*';
                                        obj[7] = Math.floor(Math.random()*10);
                                        obj[8] = Math.floor(Math.random()*10);
                                        obj[9] = Math.floor(Math.random()*10);
                                        obj[10] = Math.floor(Math.random()*10);
                                        arr.push(obj.join(''))
                                    }
                                    this.prizeNum7 = arr
                                }
                            },100)
                        }
                    }else {
                        this.$message.warning(res2.msg)
                    }
                })
            },
            stopLucky() {
                this.$axios.post(this.apiUrl.luckDrawSurvey, {
                    type: this.type
                }).then(res => {
                    if(res.code == 1) {
                        this.getList();
                    } else {
                        this.$message.warning(res.msg)
                    }
                    if(this.type == 5) {
                        this.prizeNum5 = '***********'
                    }else if(this.type == 6) {
                        this.prizeNum6 = [
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                            '***********',
                        ]
                    }else if(this.type == 7) {
                        this.prizeNum7 = [
                            '***********',
                            '***********',
                        ]
                    }
                    this.type = 0;
                    this.typeVisible = false;
                    clearInterval(this.times);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .celebrate-content{
        color: #fff;
        background: url("../../assets/img/celebrate.jpg") no-repeat;
        background-size: cover;
        background-position: bottom;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        .btn{
            font-size: 32px;
        }
        .celebrate-title{
            font-size: 60px;
            color: #fff;
            text-align: center;
            padding-top: 20px;
            text-shadow: 0 2px 5px #fff;
        }
        .celebrate-main{
            display: flex;
            padding: 30px;
            flex-wrap: wrap;
        }
        .celebrate-item{
            width: 50%;
            text-align: center;
            margin-bottom: 60px;
            box-sizing: border-box;
            //border-right: 2px solid #fff;
            &:nth-child(2n){
                // padding-left: 20px;
                border-left: 2px solid #fff;
                border-right: 0;
            }
            h3{
                font-size: 50px;
                margin-top: 10px;
            }
            ul{
                margin-top: 10px;
                display: flex;
                flex-wrap: wrap;
                text-align: center;
                align-items: center;
                align-content: center;
                justify-content: center;
                li{
                    display: inline-block;
                    font-size: 26px;
                    color: #f5f5f5;
                    width: 20%;
                }
            }
        }
    }
    .prizeNum{
        font-size: 30px;
    }
</style>

